.buttonsBlock {
    width: fit-content;
    margin-right: 0px;
    a {
        display: inline-block;
      }
  }
  input[type=file] {
    border: none;
  }

  .min-w-32:nth-child(2){
    padding: 1rem 2.5rem;
    line-height: 21px;
  }
  
.collections table{
  td:nth-child(1), td:nth-child(2){
    text-align: inherit;
  }
}