.buttonsBlock {
    width: fit-content;
    margin-right: 0px;
    a {
        display: inline-block;
      }
  }
  input[type=file] {
    border: none;
  }

  .min-w-32:nth-child(2){
    padding: 1rem 2.5rem;
    line-height: 21px;
  }
  
.maps table{
  td:nth-child(1), td:nth-child(2){
    text-align: inherit;
  }
}


td {
  max-width: 200px; /* Set your desired max width */
  word-wrap: break-word; /* Break long words to fit within the max width */
  word-break: break-word; /* Handle very long strings */
  white-space: normal; /* Allow wrapping to the next line */
}

