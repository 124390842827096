@import "../../mixins/scrollbar";
section {
  .login {
    .bigTitle {
      font-variant: small-caps;
      font-weight: 600;
    }
  }

  .container {
    background-color: #fff;
    max-height: 98%;
    box-shadow: 0px 7px 40px 20px #00000052;
    overflow-y: auto;
    @include niceScroll;
  }
}
