@import "../../mixins/scrollbar";

table {
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  margin: 1rem auto 0;
  max-width: 100%;

  @include niceScroll;

  td,
  th {
    padding: 1rem 0.5rem;
    text-align: center;
  }
  th {
    & > div {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
  }
  tr.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}
