/* Container styling */
.tabs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    height: 100%;
}

/* Tab buttons container */
.tab-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 10px;

}

/* Individual tab buttons */
.tab-button {
    flex: 1;
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 16px;
    transition: color 0.3s, background-color 0.3s;
}

.tab-button:hover {
    background-color: #f0f0f0;
}

/* Active tab button */
.tab-button.active {
    color: rgb(30 51 76);
    border-bottom: 2px solid rgb(30 51 76);
    font-weight: bold;
}

/* Tab content */
.tab-content {
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    text-align: center;
    height: 80%;
}

.map_visualizer{
    width: 85%;
    height: 100%;
}

.tab-view {
    height: 100%;
    .lista{
        width: 15%; 
        max-height: 100%; 
    }
}
.maps{
    height: 100%;
}

li > div {
   margin-left: 0px;
}
.lefty{
    text-align: left;
}

.lefty:hover{
    background-color: rgb(224 231 255);
}

iframe img{
    max-width: 600px;
}