.toastWrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  backdrop-filter: blur(2px) brightness(70%);
}

.toast {
  position: fixed;
  z-index: 1000;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  min-width: 20rem;
  max-width: 97vw;
  transition: all 0.5s ease-in-out;

  &.middle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.topRight {
    top: 1rem;
    right: 1rem;
  }
  &.topLeft {
    top: 1rem;
    left: 1rem;
  }
  &.bottomLeft {
    bottom: 1rem;
    left: 1rem;
  }
  &.bottomRight {
    bottom: 1rem;
    right: 1rem;
  }
}
