.datePickerWrapper {
  & > div:first-child {
    margin-right: 1rem;
  }
}

.headerPage{
  div{
    display: inline-block;
    vertical-align:middle;
  }
  > div:nth-child(2) {
    margin-left: 23rem;
    height: 40px;
  }
}

.subscriptionChecker ul{
    max-height: 43vh !important;
}

.nodisplay{
  display:none;
}

.already{
  pointer-events: none;
  background-color: rgba(160, 201, 160, 0.142);
  .block > input{
    opacity: 0.5;
    background-color: grey;
    border-color: grey;
  }
}


.react-date-picker__wrapper {
  border-radius: 0.25rem;
  border-color: #d4d4d4 !important;
  padding: 0.25rem;
  transition: all 0.2s linear;
}

.react-date-picker {
  width: 100%;
}

.react-date-picker--open {
  .react-date-picker__wrapper {
    transition: all 0.2s linear;
    border-color: #3b71ca;
    border-width: 2px;
  }
}

.addButton {
  width: fit-content;
  margin-right: 1rem;
}

li {
  & > div {
    margin-left: 1rem;
  }
}


.new_sub ul {
  max-height: 60vh;
}

.Mobile {
  .datePickerWrapper {
    flex-direction: column;
    & > div {
      margin: 0.5rem 0;
    }
  }
}

.niceScroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.niceScroll::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(107, 107, 107, 0.3);
}

.niceScroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #e4c643;
}

.subscriptions table{
  td:nth-child(1), td:nth-child(2){
    text-align: inherit;
  }
}

.button-group > a {
  display: inline-block;
}

.example-text{
  font-size: 0.75rem;
  color: grey;
}
.show-assignment .dynamic-span {
  display: block; 
}

.dynamic-span {
  display: none; 
   font-weight: bold;
  color: #eecc36;

  &.redAssignment{
    color: rgb(255 38 88)
  }
}



.tableBoth td:nth-child(2), .tableBoth th:nth-child(2), .tableBoth td:nth-child(3), .tableBoth th:nth-child(3) {
  display:none;
}
.tableCollection td:nth-child(3), .tableCollection th:nth-child(3) {
  display:none;
}

.tableUai td:nth-child(2), .tableUai th:nth-child(2) {
  display:none;
}

table td{
  max-width: 400px;
}
table{
  width: fit-content;
}