.togglebuttons{

    div{
        &:first-child button{
            border-radius: 0.25rem 0 0 0.25rem;
        }
        &:last-child button {
            border-radius: 0 0.25rem 0.25rem 0;
        }

        &:not(.selected) button{
            filter: brightness(90%);
        }

        &.selected button{

        }
        button{

        }
    }


}