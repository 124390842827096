nav {
  // background-color: rgb(30 51 76);
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100vh;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 0.25rem;
      padding: 0.5rem 1rem;

      &.active {
        font-weight: 600;
        background-color: #312e81;
      }

      &.subMenu {
        font-size: smaller;
        padding: 0.1rem 1rem 0.1rem 2.5rem;

        
        .notification{
          width: 1.25rem;
          height: 1.25rem;
          font-size: 0.75rem;
          text-align: center;
          border-radius: 50%;
          font-weight: bold;
          transform: translate(0px, -5px);
        }
      }

    }

    .iconWrapper {
      height: fit-content;
    }


    .logout {
      margin-top: 2rem;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.25rem;

    &.home {
      font-variant: small-caps;
      color: rgb(255, 255, 255);
      font-weight: 600;
      font-size: x-large;
      height: 3rem;
      text-align: center;
      display: block;
    }
  }

  &.Mobile {
    background-color: rgb(30 51 76);
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    width: 100%;
    height: 3rem;

    .home {
      height: 100%;
    }

    label {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
      cursor: pointer;

      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"]:checked ~ span:nth-of-type(1) {
        transform: rotatez(45deg) translate(8px, 8px);
      }

      input[type="checkbox"]:checked ~ span:nth-of-type(2) {
        transform: rotatez(-45deg);
      }

      input[type="checkbox"]:checked ~ span:nth-of-type(3) {
        transform: rotatez(-45deg);
        width: 0%;
      }

      span {
        background: #fff;
        border-radius: 10px;
        height: 0.1rem;
        width: 100%;
        transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
      }
    }

    ul {
      position: absolute;
      top: 3rem;
      right: 0;
      color: rgb(30 51 76);
      background-color: #fff;
      gap: 0.5rem;
      padding: 1rem 0;
      z-index: 1;
      box-shadow: 0 0 14px 3px rgb(30 51 76 / 29%);

      .logout {
        border-top: 1px solid #1e334c63;
        margin-top: 0.5rem;
        padding-top: 0.5rem;
      }
    }
  }
}
