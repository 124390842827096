@import "mixins/scrollbar";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  #root {
    display: flex;
    justify-content: center;
    height: 100vh;

    main {
      display: flex;
      width: 100%;

      section.masterSection {
        position: relative;
        overflow-y: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include niceScroll;
      }

      &.Mobile {
        flex-direction: column;
      }

      .centered{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .blur{
        width: 100%;
        height: 100%;
        background: #fff;
        opacity: 0.6;
      }
    }

    .title {
      font-weight: 500;
      color: rgb(30 51 76);
      font-size: 1.25rem;
      line-height: 1.75rem;
      padding: 1.25rem;
      max-width: 60%;
    }
  }
}
